import * as React from "react";
import { useMediaQuery } from "react-responsive";
import {
  container,
  siteTitle,
  menuItem,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";

// markup
const RegistryPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <embed
        src="https://www.zola.com/wedding/rsvp_vp/rsvp"
        style={{width:'500px', height: '300px'}}
      ></embed>
    </div>
  );
};

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>RSVP</p>
    </div>
  );
};

export default RegistryPage;
